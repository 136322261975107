
import Vue from "vue";

export default Vue.extend({
  name: "AdminUserMenu",
  props: {
    menuItems: {
      type: Array,
      required: true
    },
    selectedTabKey: {
      type: String,
      required: true
    }
  }
});
